<template>
  <div id="news-article-detail" v-loading="isLoading">
    <el-form
      ref="form"
      :model="articleForm"
      :rules="rules"
      label-position="top"
      :disabled="!isAdding && (isEditing && !checkAccessRight('edit'))"
      @submit.native.prevent="handleSubmit"
    >
      <el-row :gutter="40" class="article-content">
        <el-col :xs="24" :md="12">
          <el-form-item
            :label="$t('TITLE_PHOTO') + ` (${$t('RECOMMENDED_TITLE_PHOTO_SIZE')})`"
            prop="coverPhoto"
            class="is-required"
          >
            <image-upload
              :photoFileUpload.sync="articleForm.coverPhoto"
              isForm
              :disabled="!isAdding && (isEditing && !checkAccessRight('edit'))"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :md="6">
          <el-form-item :label="$t('PUBLISH_DATE')" prop="publishDate">
            <el-date-picker
              class="w-100"
              disabled
              v-model="articleForm.publishDate"
              :size="isMobile ? 'mini' : null"
              placement="bottom-start"
              prefix-icon="custom-icon icon-calendar mini"
              :format="isMobile ? EL_DATE_FORMAT_MOBILE : EL_DATE_FORMAT"
              value-format="timestamp"
              @focus="e => e.blur()"
            />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :md="6">
          <el-form-item :label="$t('STATUS')" prop="status">
            <el-select
              class="w-100"
              :disabled="canChangeStatus(articleForm.status) && isAdding"
              v-model="articleForm.status"
              :class="getStatusClass(articleForm.status)"
            >
              <el-option
                v-for="item in allSelectableStatusListFor(articleForm.status)"
                :key="item.value"
                :label="$t(item.value)"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :md="12">
          <el-form-item
            :label="$t('NEWS_CATEGORY')"
            prop="category"
            class="is-required"
          >
            <el-select class="w-100" v-model="articleForm.category.id">
              <el-option
                v-for="el in categoryList"
                :key="el.id"
                :label="el.translationList | translate"
                :value="el.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-tabs
        tab-position="top"
        class="detail-language-options"
        type="card"
        stretch
        v-model="selectedLanguage"
        :before-leave="changeLang"
      >
        <el-tab-pane v-for="el in getLangList()" :key="el.code" :name="el.code">
          <el-row type="flex" align="middle" slot="label">
            <img
              class="custom-icon icon-lang mini"
              :src="getLangFlagImage(el.code)"
            />
            <span
              v-if="!isMobile || el.code === 'hi' || el.code === 'ta'"
            >{{ el.label }}</span>
          </el-row>
        </el-tab-pane>

        <el-row :gutter="40" class="article-content">
          <!-- hide to display article name in all pages -->
          <!-- <el-col :xs="24" :md="12">
            <el-form-item
              :label="$t('ARTICLE_NAME')"
              prop="name"
              class="is-required"
            >
              <el-input
                v-model="articleName"
                type="textarea"
                resize="none"
                :rows="2"
                maxlength="200"
                show-word-limit
              >
              </el-input>
            </el-form-item>
          </el-col> -->
          <el-col :xs="24" :md="24">
            <el-form-item
              :label="$t('ARTICLE_META_TITLE', selectedLanguage)"
              prop="metaTitle"
              class="is-required"
            >
              <el-input
                v-model="articleMetaTitle"
                type="textarea"
                resize="none"
                :rows="2"
                maxlength="60"
                show-word-limit
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :md="24">
            <el-form-item
              :label="$t('ARTICLE_TITLE', selectedLanguage)"
              prop="title"
              class="is-required"
            >
              <el-input
                v-model="articleTitle"
                type="textarea"
                resize="none"
                :rows="2"
                maxlength="200"
                show-word-limit
              >
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :md="24">
            <el-form-item
              :label="$t('ARTICLE_SHORT_DESC',selectedLanguage)"
              prop="description"
              class="is-required"
            >
              <el-input
                v-model="articleDesc"
                type="textarea"
                resize="none"
                :rows="6"
                maxlength="200"
                show-word-limit
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="24" v-if="!isLoading">
            <el-form-item
              :label="$t('ARTICLE_CONTENT',selectedLanguage)"
              prop="body"
              class="is-required"
            >
              <html-editor 
                :key="editorId" 
                :modelValue.sync="articleBody" 
                :length.sync="textLength" 
                insertMiddleAds
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-tabs>
      <page-footer
        :isLoading="isLoading"
        :handleBackTo="exit"
        :handleArchive="showArchiveBtn ? () => handleArchive() : null"
        :handleSubmit=" showSubmitBtn ? () => {} : null"
        :submitBtnText="isEditing ? $t('SAVE') : $t('ADD')"
        :handleAction="showSubmitBtn ?  () => handlePreview() : null"
        :actionBtnText="$t('PREVIEW')"
      />
    </el-form>    
  </div>
</template>

<script>
import { generalMixin } from "@/utils/general-mixin";
import { articleMixin } from '@/utils/_mixins/article'
import cloneDeep from "lodash/cloneDeep";
import HTMLEditor from "@/components/quill/html-editor";
import ImageUpload from "@/components/image-upload.vue";
import PageFooter from "@/components/page-footer.vue";
import { getAllCategory } from "@/api/category.js";
import {
  addArticle,
  getBOArticleById,
  udpateArticle,
  archiveArticle,
} from "@/api/article.js";
import moment from "moment";
import { mapGetters } from "vuex";
import i18n from "@/locales";
import { validateCharacter } from "@/utils/helpers.js";

// [] = {name:'',language:{id}}
const formFields = {
  title: [],
  metaTitle: [],
  description: [],
  body: [],
  // name: [],
  status: "ACTIVE",
  publishDate: moment().valueOf(),
  category: {
    id: null,
  },
  user: {
    id: null,
  },
  coverPhoto: null,
};

const formRules = {
  // name: [
  //   {
  //     required: true,
  //     message: i18n.t("ARTICLE_NAME_REQUIRED"),
  //     trigger: "blur",
  //   },
  // ],
  title: [
    {
      required: true,
      message: i18n.t("ARTICLE_TITLE_REQUIRED"),
      trigger: "blur",
    },
  ],
  metaTitle: [
    {
      required: true,
      message: i18n.t("ARTICLE_META_TITLE_REQUIRED"),
      trigger: "blur",
    },    
  ],
  description: [
    {
      required: true,
      message: i18n.t("ARTICLE_DESC_REQUIRED"),
      trigger: "blur",
    },
  ],
  body: [
    {
      required: true,
      message: i18n.t("ARTICLE_CONTENT_REQUIRED"),
      trigger: "blur",
    },
  ],
  coverPhoto: [
    {
      required: true,
      message: i18n.t("ARTICLE_IMAGE_REQUIRED"),
      trigger: "change",
    },
  ],
};

export default {
  name: "ArticleDetail",
  mixins: [generalMixin, articleMixin],
  components: {
    ImageUpload,
    "html-editor": HTMLEditor,
    PageFooter,
  },
  data() {    
    return {
      isLoading: false,
      id: null,
      isAdding: false,
      isEditing: false,
      articleForm: cloneDeep(formFields),
      rules: cloneDeep(formRules),
      categoryList: [],
      moment,
      selectedLanguage: this.locale || "en",
      editorId: 0,
      textLength: 0
    };
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("app", ["getLangList", "getLangFlagImage"]),
    showSubmitBtn() {
      const canAdd = this.isAdding && this.checkAccessRight('add')
      const canEdit = this.isEditing && this.checkAccessRight('edit')
      return canAdd || canEdit
    },
    showArchiveBtn() {
      const canArchive = this.checkAccessRight('archive') && this.isEditing;
      return canArchive
    },
    // articleName: {
    //   get() {
    //     const locale = this.selectedLanguage;
    //     return this.getNameFromTransList(this.articleForm.name, "", locale);
    //   },
    //   set(newValue) {
    //     this.formatLangObj("name", newValue);
    //   },
    // },
    articleTitle: {
      get() {
        const locale = this.selectedLanguage;
        return this.getNameFromTransList(this.articleForm.title, "", locale);
      },
      set(newValue) {
        // replicate this into name field
        this.formatLangObj("title", newValue);
        // this.formatLangObj("name", newValue);
      },
    },
    articleMetaTitle: {
      get() {
        const locale = this.selectedLanguage;
        return this.getNameFromTransList(this.articleForm.metaTitle, "", locale);
      },
      set(newValue) {
        this.formatLangObj("metaTitle", newValue);
      },
    },
    articleDesc: {
      get() {
        const locale = this.selectedLanguage;
        return this.getNameFromTransList(
          this.articleForm.description,
          "",
          locale
        );
      },
      set(newValue) {
        this.formatLangObj("description", newValue);
      },
    },
    articleBody: {
      get() {
        const locale = this.selectedLanguage;
        const body = this.getNameFromTransList(
          this.articleForm.body,
          "",
          locale
        );
        return body;
      },
      set(newValue) {
        this.formatLangObj("body", newValue);
      },
    },
  },
  watch: {
    selectedLanguage(val) {
      if(val){
        this.$refs["form"].validate()
      }
    }
  },
  methods: {
    handlePreview() {
      let postData = this.formValidation()
      if(postData) {
        this.$store.dispatch('article/previewArticle',postData)
        this.$nextTick(() => {
          this.goTo(`/0?locale=${this.selectedLanguage}`, true)
        })
      }
    },
    formValidation() {
      let verifiedData = null
      // console.log("valid.....",verifiedData);
      this.$refs["form"].validate(valid => {
        if (!valid) verifiedData = null;
        else {
          // console.log("submitting.....");
          const postData = cloneDeep(this.articleForm)
          postData.fileUpload = postData.coverPhoto
          delete postData.coverPhoto
          verifiedData = postData
        }
      });
      return verifiedData
    },
    iniPlaceholder(body) {
      let placeholderHTML = '<pre class="adv-block" id="adv-middle" data-label="Advertisement Here" spellcheck="false" contenteditable="false"><span contenteditable="false">Advertisement Here</span></pre><br><p></p>'
      let idString = "adv-middle"; // id-name
      const result  = body.filter( article => {
        if (article.name.includes(idString)) return article
        else {
          // Placeholder if user accidentally detele it
          article.name = article.name + placeholderHTML
          return article
        }
      })

      return result
    },
    changeLang(_,oldV) {
      this.editorId++;
      // Prevent empty content in article body
      if(this.textLength ===0){
        let index = this.articleForm.body.filter(el => {
           return el.language.code != oldV 
        });
        this.articleForm.body = index
      } 
    },
    validateTitle(_, __, callback) {
      if (this.articleTitle.trim().length <= 0) {
        callback(new Error(this.$t("ARTICLE_TITLE_REQUIRED")))
      }else {
        callback()
      }
    },
    validateDesc(_, __, callback) {
      if (this.articleDesc.trim().length <= 0) {
        callback(new Error(this.$t("ARTICLE_DESC_REQUIRED")))
      }else {
        callback()
      }
    },
    validateMetaTitle(_, __, callback) {
      if ((this.articleMetaTitle === "" && this.articleTitle !== '') || this.articleMetaTitle.trim().length <= 0) {
        callback(new Error(this.$t("ARTICLE_META_TITLE_REQUIRED")))
      } else if (validateCharacter(this.articleMetaTitle)) {
        callback(new Error(this.$t("ARTICLE_META_INVALID_FORMAT")));
      } else {
        callback()
      }
    },
    validateContent(_, __, callback) {
      if (this.textLength <= 0 && this.articleForm.body.length == 1) {
        callback(new Error(this.$t("ARTICLE_CONTENT_REQUIRED")));
      } else {
        callback()
      }
    },
    formatLangObj(field, value) {
      const locale = this.selectedLanguage;
      let index = this.articleForm[field].findIndex(
        (el) => el.language.code == locale
      );
      if(value) {
        let obj = this.articleForm[field][index];
        if (obj == null) {
          obj = {
            name: value,
            language: this.getLangList().find((el) => el.code == locale),
          };
          index = this.articleForm[field].length;
        } else {
          obj.name = value;
        }
        this.$set(this.articleForm[field], index, obj);
      } else {
        // Prevent empty content in translation array
        this.articleForm[field] = this.articleForm[field].filter(el => {
          return el.language.code != locale
        })
        

      }
    },
    exit() {
      this.goTo("/bo/article");
    },
    getCategory() {
      const postData = {
        genericRecord: {
          status: "ACTIVE",
          type: "category",
        },
      };
      getAllCategory(postData).then((res) => {
        if (res.result) {
          this.categoryList = cloneDeep(res.result.list);
          this.articleForm.category.id = this.categoryList[0].id;
        }
      });
    },
    mountedFn() {
      if (this.id) {
        this.isEditing = true;
        this.getDetail();
      } else if (this.checkAccessRight('add')) {
        this.isAdding = true;
      }
    },
    handleArchive() {
      // console.log('archive')
      archiveArticle(this.id)
        .then((res) => {
          if (res.status == "ok") {
            this.$notify({
              type: "success",
              message: this.$t("UPDATE_SUCCESSFULLY"),
              duration: this.NOTIFY_DURATION,
              position: this.NOTIFY_POSITION
            });
          }
        })
        .catch(this.handleError)
        .finally(() => this.exit());
    },
    async handleSubmit() {
      let postData = this.formValidation()
      if(postData) {
        postData.body = this.iniPlaceholder(postData.body)             
        
        if(this.metaTitleValidation(postData)) {          
          if (this.isAdding) this.handleAdd(postData);
          else this.handleEdit(postData);
        } else {          
          // trigger validation when no meta title
          return this.$refs["form"].validate()
        }        
      } else {                
        return
      } 
    },
    metaTitleValidation(data) {
      // redirect to that laguage if any of the language is with contents, but no meta title value      
      if(data.metaTitle.length != data.title.length && data.title.length > 0) {
        var title = data.title
        var metaTitle = data.metaTitle
        var result = title.filter(function(o1){
            // filter out (!) items in result2
            return !metaTitle.some(function(o2){
                return o1.language.id === o2.language.id;
            });
        })
        // console.log(result)
        this.selectedLanguage = result[0].language.code
        return false
      } else return true
    },
    handleAdd(postData) {
      this.isLoading = true;
      addArticle(postData)
        .then((res) => {
          if (res.status == "ok") {
            this.$notify({
              type: "success",
              message: this.$t("ADDED_SUCCESSFULLY"),
              duration: this.NOTIFY_DURATION,
              position: this.NOTIFY_POSITION
            });
            if (res.result?.coins && res.result?.transactionType === "IN") {
              this.$notify({
                title: this.$t("GAINED_COIN_TITLE"),
                message: this.$t("GAINED_COIN_MSG", { amount: res.result.coins }),
                type: 'success',
                duration: this.NOTIFY_DURATION,
                position: this.NOTIFY_POSITION,
                dangerouslyUseHTMLString: true
              })
            }
            this.exit();
          }
        })
        .catch(this.handleError)
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleEdit(postData) {      
      this.isLoading = true;
      udpateArticle(postData)
        .then((res) => {
          if (res.status == "ok") {
            this.$notify({
              type: "success",
              message: this.$t("UPDATE_SUCCESSFULLY"),
              duration: this.NOTIFY_DURATION,
              position: this.NOTIFY_POSITION
            });
            this.exit();
          }
        })
        .catch(this.handleError)
        .finally(() => {
          this.isLoading = false;
        });
    },
    getDetail() {
      this.isLoading = true;
      let pData = {
        id: this.id,
        getWhenCategoryInactive: true,
      };
      getBOArticleById(pData)
        .then((res) => {
          if (res.status == "ok") {
            this.articleForm = cloneDeep(res.result);
          }
        })
        .catch(this.handleError)
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.getCategory();
    this.id = this.$route.params.id;
    this.articleForm.user.id = this.user.id;
    
    let validator = { validator: this.validateContent, trigger: 'blur' }
    let validator1 = { validator: this.validateMetaTitle, trigger: 'blur' }
    let validatorTitle = { validator: this.validateTitle, trigger: 'blur' }
    let validatorDesc = { validator: this.validateDesc, trigger: 'blur' }
    // let validator1 = { validator: this.validateMetaTitle, trigger: 'blur' }
    this.rules.body.push(validator)
    this.rules.metaTitle.push(validator1)    
    this.rules.title.push(validatorTitle)
    this.rules.description.push(validatorDesc)
    this.mountedFn();
  },
  async beforeRouteLeave(_, __, next) {
    // Remove preview before exit 
    this.$store.dispatch('article/previewArticle',null)
    next();
  },
};
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";
@import "@/assets/style/mixin/general.scss";
@import "@/assets/style/mixin/detail.scss";

#news-article-detail {
  @include detail-language-options;
  @include detail-form;

  background-color: $white;
  border-radius: 10px;
  padding-bottom: 2rem;
  padding-top: 1rem;

  .article-content {
    padding: 0 0.8rem;
    margin-bottom: 1rem;
  }

  .id-block {
    background: #f3f5fa 0% 0% no-repeat padding-box;
    border-radius: 15px;
    opacity: 1;
    letter-spacing: 0px;
    color: #666c79;
    padding: 10px;
    font-size: 12px;

    b {
      color: #253858;
    }
  }

  .break-line {
    border-top: 1px dashed #e8e8e8;
    margin-bottom: 1rem;
  }

  // .el-tabs__header,.el-tabs__nav,.el-tabs__item {
  //   border-color: #fa5b1d!important;
  //   &.is-active{
  //     border-bottom-color: #FFFFFF!important;
  //   }
  // }

  .image-upload.form .change-delete-text-wrapper,.image-upload.form .upload-text {
    right: unset;
    left: 16px;
  }

  .icon-lang {
    margin-right: 5px;
  }
}

.mobile {
  #news-article-detail {
    @include detail-language-options-mobile;

    .image-upload.form .change-delete-text-wrapper, .image-upload.form .upload-text {
      right: 0px!important;
      width: 100%;
      left: 0px!important;
    }

  }
}
</style>
